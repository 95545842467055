a {
    color: rgb(43, 127, 242);
    text-decoration: none;
}

[class*="subject-color-biology"] {
    background: #d6ead2 !important;
}

[class*="subject-color-chemistry"] {
    background: #f1d4b2 !important;
}

[class*="subject-color-physics"] {
    background: #d3edfc !important;
}

[class*="subject-color-mathematics"] {
    background: #d1d1d1 !important;
}

[class*="subject-color-maths"] {
    background: #acacad !important;
}

[class*="subject-color-english"] {
    background: #b8e3e6 !important;
}

[class*="subject-color-spoken-english"] {
    background: #78cdd2 !important;
}

[class*="subject-color-computing"] {
    background: #ddeeea !important;
}

[class*="subject-color-computer-science"] {
    background: #ddeeea !important;
}

[class*="subject-color-evs"] {
    background: #b1ded2 !important;
}

[class*="subject-color-science"] {
    background: #f0edf5 !important;
}

[class*="subject-color-geometry"] {
    background: #38ada9 !important;
}

[class*="subject-color-geometry"][data-curriculum="CCSS"] {
    background: #dededf !important;
}

[class*="subject-color-design and technology"] {
    background: #e2e2ef !important;
}

[class*="subject-color-numbers-concept"] {
    background: #dededf !important;
}

[class*="subject-color-geometry-concept"] {
    background: #dededf !important;
}

[class*="subject-color-algebra"],
[class*="subject-color-algebra-concept"] {
    background: #dededf !important;
}

[class*="subject-color-measurement-concept"] {
    background: #dededf !important;
}

[class*="subject-color-statistics-and-probability"],
[class*="subject-color-statistics-concept"] {
    background: #dededf !important;
}

[class*="subject-color-numeracy-and-mathematics"],
[class*="subject-color-numbers-and-quantities"],
[class*="subject-color-functions"] {
    background: #d1d1d1 !important;
}

[class*="subject-color-earth-and-space-science"] {
    background: #c9d1db !important;
}

[class*="subject-color-earth-and-space-science h1"] {
    color: white !important;
}

[class*="subject-color-physical-science"] {
    background: #eef9fe !important;
}

[class*="subject-color-life-science"] {
    background: #d6ead2 !important;
}

[class*="subject-color-computing"],
[class*="subject-color-network-and-the-internet"],
[class*="subject-color-networks-and-the-internet"],
[class*="subject-color-data-and-analysis"],
[class*="subject-color-impacts-of-computing"],
[class*="subject-color-algorithms and-programming"],
[class*="subject-color-informatics"] {
    background: #ddeeea !important;
}

[class*="subject-color-administration-and-support"],
[class*="subject-color-business-management"],
[class*="subject-color-customer-service-and-sales"],
[class*="subject-color-enterprise-and-entrepreneurship"],
[class*="subject-color-event-management"],
[class*="subject-color-finance-and-accounting"],
[class*="subject-color-human-resources"],
[class*="subject-color-marketing"],
[class*="subject-color-project-management"] {
    background: #e5e5e5 !important;
}

[class*="subject-color-plantyn"],
[class*="subject-color-environmental-sciences"] {
    background: #edebf4 !important;
}

[class*="subject-color-anatomy-and-physiology"],
[class*="subject-color-coaching"],
[class*="subject-color-health"],
[class*="wellbeing-and-sport"],
[class*="subject-color-nutrition-and-sport"],
[class*="subject-color-outdoor-and-adventurous-activities"],
[class*="subject-color-research-methods"],
[class*="subject-color-sports-psychology"],
[class*="subject-color-working-in-the-sports-industry"],
[class*="subject-color-health"],
[class*="wellbeing-and-exercise"],
[class*="subject-color-fitness-testing-and-training"],
[class*="subject-color-nutrition-for-sports-and-performance"] {
    background: #eff4ff !important;
}

[class*="subject-color-mens-en-natuur"] {
    background: #f8f6da !important;
}

[class*="subject-color-mens-en-maatschappij"] {
    background: #f0eae8 !important;
}

[class*="subject-color-burgerschap"] {
    background: #fff4f9 !important;
}

[class*="subject-color-kunst-en-cultuur"] {
    background: #eae2e8 !important;
}

[class*="subject-color-historische-tijd-geschiedenis"] {
    background: #ffeaec !important;
}

[class*="subject-color-wetenschap--techniek--mn"] {
    background: #eae6f6 !important;
}

[class*="subject-color-plantyn"],
[class*="subject-color-environmental-sciences"] {
    background: #edebf4 !important;
}

[class*="subject-color-life-and-earth-sciences"] {
    background: #d6ead2 !important;
}

[class*="subject-color-social-studies"],
[class*="subject-color-social-science"],
[class*="subject-color-history"] {
    background: #d4cfe3 !important;
}

[class*="subject-color-geography"] {
    background: #eff2e6 !important;
}

[class*="subject-color-civics"],
[class*="subject-color-political-science"] {
    background: #e5ece6 !important;
}

[class*="subject-color-learning-skills"] {
    background: #e7fbfa !important;
}

[class*="subject-color-life-skills"] {
    background: #e9f6fa !important;
}

[class*="subject-color-literacy-skills"] {
    background: #dff3f1 !important;
}

[class*="subject-color-kanjerkrant"] {
    background: #f0edf5 !important;
}

[class*="subject-color-scoodle"] {
    background: #f0edf5 !important;
}

[class*="subject-color-aardrijkskunde"] {
    background: #e5ece6 !important;
}

[class*="subject-color-electro-mechanical-engineering"] {
    background: #edebf4 !important;
}

[class*="subject-color-aarde-en-heelal"] {
    background: #c9d1db !important;
}

[class*="subject-color-cultuur-en-godsdienst"] {
    background: #f0eae8 !important;
}

[class*="subject-color-dieren"] {
    background: #d6ead2 !important;
}

[class*="subject-color-eten-en-drinken"] {
    background: #f7edf3 !important;
}

[class*="subject-color-geschiedenis"] {
    background: #ffeaec !important;
}

[class*="subject-color-kunst-en-creatief"] {
    background: #eae2e8 !important;
}

[class*="subject-color-lichaam-en-gevoel"] {
    background: #f0eae8 !important;
}

[class*="subject-color-mens-en-samenleving"] {
    background: #f5e1ed !important;
}

[class*="subject-color-natuur"] {
    background: #d6ead2 !important;
}

[class*="subject-color-school"] {
    background: #d3edfc !important;
}

[class*="subject-color-sport-en-vrije-tijd"] {
    background: #d3edfc !important;
}

[class*="subject-color-wetenschap-en-techniek"] {
    background: #f1d4b2 !important;
}

[class*="subject-color-wereld"] {
    background: #e5ece6 !important;
}
[class*="subject-color-general-science"] {
    background: #f0edf5 !important;
}

[class*="subject-color-physical-science"] {
    background: #d3edfc !important;
}
