$zindex-tooltip: 11;
$zindex-filter-input: 900;
$zindex-loading: 900;
$zindex-selectable-dropdown: 1000;
$zindex-search-input-desktop: 1000;
$zindex-navigators: 1100;
$zindex-search-input-form: 1200;
$zindex-curriculum-menu: 1300;
$zindex-reading-level-modal: 1300;
$zindex-search-input-mobile: 1400;
$zindex-menu-container: 1400;
$zindex-search-autocomplete: 1500;
$zindex-context-menu: 1500;
$zindex-result-modal: 1600;
$zindex-message-modal: 1700;
$zindex-alert-modal: 1800;

:export {
    zindex-tooltip: $zindex-tooltip;
    zindex-filter-input: $zindex-filter-input;
    zindex-loading: $zindex-loading;
    zindex-selectable-dropdown: $zindex-selectable-dropdown;
    zindex-search-input-desktop: $zindex-search-input-desktop;
    zindex-navigators: $zindex-navigators;
    zindex-search-input-form: $zindex-search-input-form;
    zindex-curriculum-menu: $zindex-curriculum-menu;
    zindex-reading-level-modal: $zindex-reading-level-modal;
    zindex-search-input-mobile: $zindex-search-input-mobile;
    zindex-menu-container: $zindex-menu-container;
    zindex-search-autocomplete: $zindex-search-autocomplete;
    zindex-context-menu: $zindex-context-menu;
    zindex-result-modal: $zindex-result-modal;
    zindex-message-modal: $zindex-message-modal;
    zindex-alert-modal: $zindex-alert-modal;
}
